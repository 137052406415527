import { createGlobalStyle, DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
  colors: {
    primary: '#A91480',
    secondary: '#EE7800',
    typographyHeading: '#1E201D',
    typographyBody: '#626561',
    backgroundPrimary: '#FFFFFF',
    backgroundAccent: '#FBF3F9',
  },
  /* Heading 1 */
  typography1: {
    fontWeight: '600',
    lineHeight: '133%',
    fontSize: '2.625rem',
  },
  /* Heading 2 */
  typography2: {
    fontWeight: '600',
    lineHeight: '144%',
    fontSize: '2rem',
  },
  /* Title */
  typography3: {
    fontWeight: '600',
    lineHeight: '160%',
    fontSize: '1,25rem',
  },
  /* Body */
  typography4: {
    fontWeight: '400',
    lineHeight: '150%',
    fontSize: '1rem',
  },
  /* Action */
  typography5: {
    fontWeight: '600',
    lineHeight: '150%',
    fontSize: '1rem',
  },
  dimensions: {
    headerHeight: 124,
    mobileHeaderHeight: 70,
    searchFilterHeight: 144,
    borderRadius: 4,
  },

  spacing: (multiplier: number) => multiplier * 8,
};

export const GlobalStyles = createGlobalStyle`
html,
body {
  width: 100vw;
  height: 100vh;
  font-family : var(--font-poppins);
  overflow: hidden;
}
*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}
`;
