import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const HeaderContainer = styled.div`
  --horizontal-spacing: 1.5rem;
  container-type: normal;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  display: flex;
  height: ${(props) => props.theme.dimensions.headerHeight}px;
  align-items: center;
  padding: 0.75rem var(--horizontal-spacing);

  ${breakpoints.mobile} {
    height: ${(props) => props.theme.dimensions.mobileHeaderHeight}px;
  }
`;
