'use client';

import { Details } from '@/components/Details';
import { Results } from '@/components/Results';
import { Search } from '@/components/Search';
import { useMemberContext } from '@/context/useMemberContext';

import { MainContainer } from './styles';

export const Main = () => {
  const { member, showResults } = useMemberContext();

  return (
    <MainContainer>
      <Search />
      {showResults && <Results />}
      {member && <Details />}
    </MainContainer>
  );
};
