import styled from 'styled-components';

export const ControlPanel = styled.div(
  ({ theme }) => `
  display: block;
  position: fixed;
  z-index: 10;
  margin-top: 0.5rem;
`,
);
