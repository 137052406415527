'use client';

import { Main } from '@/components/Main';
import { Map } from '@/components/Map';

import { Header } from '../Header';
import { Container, Page } from './styles';

export const Home = () => {
  return (
    <Container>
      <Header />
      <Page>
        <Main />
        <Map />
      </Page>
    </Container>
  );
};
