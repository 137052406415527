import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 25rem;
  overflow-x: hidden;
  gap: 1rem;
  overflow-y: auto;
  position: relative;

  ${breakpoints.mobile} {
    width: 100%;
    left: 0;
    position: absolute;
    z-index: 1;
  }
`;
