'use client';

import { useMemberContext } from '@/context/useMemberContext';
import { useOrganisations } from '@/hooks/useOrganisations';

import { ResultsContainer } from './styles';

export const Results = () => {
  const { search, qualityLabel, governanceCode, socialCouncilWork, setMember } = useMemberContext();
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );

  return (
    <ResultsContainer>
      {organisations?.map((org, index) => (
        <button key={index} onClick={() => setMember(index)}>
          Result {org.Company}
        </button>
      ))}
    </ResultsContainer>
  );
};
