'use client';

import { createContext, ReactNode, useMemo, useState } from 'react';

export interface MemberContextType {
  member: number | null;
  setMember: (member: number | null) => void;
  search: string;
  setSearch: (search: string) => void;
  qualityLabel: boolean;
  setQualityLabel: (qualityLabel: boolean) => void;
  governanceCode: boolean;
  setGovernanceCode: (governanceCode: boolean) => void;
  socialCouncilWork: boolean;
  setSocialCouncilWork: (socialCouncilWork: boolean) => void;
  showResults: boolean | null;
  setShowResults: (showResults: boolean) => void;
}

const defaults: MemberContextType = {
  member: null,
  setMember: () => {},
  search: '',
  setSearch: () => {},
  qualityLabel: false,
  setQualityLabel: () => {},
  governanceCode: false,
  setGovernanceCode: () => {},
  socialCouncilWork: false,
  setSocialCouncilWork: () => {},
  showResults: false,
  setShowResults: () => {},
};
export const MemberContext = createContext<MemberContextType>(defaults);

export interface MemberProvider {
  children: ReactNode;
}

export const MemberProvider = ({ children }: MemberProvider) => {
  const [member, setMember] = useState(defaults.member);
  const [search, setSearch] = useState(defaults.search);
  const [qualityLabel, setQualityLabel] = useState(defaults.qualityLabel);
  const [governanceCode, setGovernanceCode] = useState(defaults.governanceCode);
  const [socialCouncilWork, setSocialCouncilWork] = useState(defaults.socialCouncilWork);
  const [showResults, setShowResults] = useState(defaults.showResults);

  // Hooks
  const value = useMemo(
    () => ({
      setShowResults,
      showResults,
      member,
      setMember,
      search,
      setSearch,
      qualityLabel,
      setQualityLabel,
      governanceCode,
      setGovernanceCode,
      socialCouncilWork,
      setSocialCouncilWork,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      showResults,
      setShowResults,
      member,
      setMember,
      search,
      setSearch,
      qualityLabel,
      setQualityLabel,
      governanceCode,
      setGovernanceCode,
      socialCouncilWork,
      setSocialCouncilWork,
    ],
  );

  return <MemberContext.Provider value={value}>{children}</MemberContext.Provider>;
};
