import styled from 'styled-components';

export const DetailsContainer = styled.div`
  position: absolute;
  flex: 1 0 0;
  left: 0;
  width: 100%;
  height: calc(100% - 11rem);
  top: 11rem;
  background-color: pink;
`;
