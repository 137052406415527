'use client';

import { ButtonContainer, IconContainer } from './styles';

export interface PrimaryButtonProps {
  icon?: React.ReactNode;
  label?: string;
  onClick: () => void;
}

export const PrimaryButton = ({ icon, label, onClick }: PrimaryButtonProps) => {
  return (
    <ButtonContainer onClick={onClick}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {label}
    </ButtonContainer>
  );
};
