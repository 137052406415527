import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { Organisation } from '@/lib/api-dots';

import { getOrganisations } from '../actions/get-organisations';

/**
 * @param search: empty string does not search
 * @param qualityLabel: false means not filtering alt all, true return only with values of true
 * @param governanceCode: false means not filtering alt all, true return only with values of true
 * @param socialCouncilWork: false means not filtering alt all, true return only with values of true
 */
export const useOrganisations = (
  search: string,
  qualityLabel: boolean,
  governanceCode: boolean,
  socialCouncilWork: boolean,
) => {
  const { data, isPending } = useQuery<Organisation[]>({
    queryKey: ['organisations'],
    queryFn: () => getOrganisations(),
  });

  const organisations = useMemo(() => {
    let orgs: Organisation[] = [];
    if (data) {
      orgs = data;
    }

    // Filter on booleans
    if (qualityLabel) {
      orgs = orgs.filter((org) => org.KwaliteitslabelTonen);
    }
    if (governanceCode) {
      orgs = orgs.filter((org) => org.RegisterGovernance_OK);
    }
    if (socialCouncilWork) {
      orgs = orgs.filter((org) => org.Raadslid);
    }

    // Search
    if (search) {
      const regex = new RegExp(
        search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
        'i', // "i" flag for case-insensitive search
      );
      orgs = orgs.filter(
        (org) =>
          regex.test(org.Company) ||
          regex.test(org.Country) ||
          regex.test(org.County) ||
          regex.test(org.City),
      );
    }
    return orgs;
  }, [data, qualityLabel, governanceCode, socialCouncilWork, search]);

  return { organisations, isPending };
};
