import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 25rem;
  padding: 1rem;
  height: ${(props) => props.theme.dimensions.searchFilterHeight}px;
  gap: 1rem;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  ${breakpoints.mobile} {
    background-color: yellow;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding-inline: ${(props) => props.theme.spacing(2)}px;
  padding-block: ${(props) => props.theme.spacing(1.5)}px;
  border-radius: ${(props) => props.theme.spacing(1)}px;
  border: 1px solid ${(props) => props.theme.colors.typographyBody};
  color: ${(props) => props.theme.colors.typographyBody};
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  outline: none;
  font-size: 1rem;
  color: ${(props) => props.theme.colors.typographyBody};
  background-color: transparent;
`;
