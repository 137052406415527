import { useContext } from 'react';

import { MemberContext, MemberContextType } from './MemberProvider';

export const useMemberContext = (): MemberContextType => {
  const context = useContext(MemberContext);
  if (!context) {
    throw new Error('Called useMemberContext before setting MemberProvider context');
  }
  return context;
};
