import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';

export const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow-y: auto;
  gap: ${(props) => props.theme.spacing(1)}px;
  background-color: blue;
  padding: ${(props) => props.theme.spacing(1)}px;

  ${breakpoints.mobile} {
    background-color: yellow;
    max-height: 40rem;
    flex: 1 0 auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
`;
