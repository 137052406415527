'use client';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import FilterIcon from '@images/Filter.svg';

import { PrimaryButton } from '@/components/Button';
import { useMemberContext } from '@/context/useMemberContext';

import { FilterContainer, FilterModal } from './styles';

export const Filter = () => {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);
  const { setShowResults } = useMemberContext();

  return (
    <FilterContainer>
      <PrimaryButton icon={<FilterIcon />} label={t('filters')} onClick={() => setOpen(true)} />
      {open && (
        <FilterModal>
          FilterModal
          <br />
          <button onClick={() => setOpen(false)}>Close</button>
          <br />
          <button onClick={() => setShowResults(true)}>Show result</button>
          <br />
          <button onClick={() => setShowResults(false)}>Hide result</button>
        </FilterModal>
      )}
    </FilterContainer>
  );
};
