'use client';

import SearchIcon from '@images/Search.svg';

import { Filter } from '@/components/Filter';
import { useMemberContext } from '@/context/useMemberContext';
import { lightTheme } from '@/styles/theme';

import { InputContainer, SearchContainer, SearchInput } from './styles';

export const Search = () => {
  const { search, setSearch } = useMemberContext();

  return (
    <SearchContainer>
      <InputContainer>
        <SearchInput
          type="search"
          placeholder="Zoek op locatie of lid..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <SearchIcon fill={lightTheme.colors.typographyBody} />
      </InputContainer>
      <Filter />
    </SearchContainer>
  );
};
