import { Marker } from 'react-map-gl';

import LocationIcon from '@images/Location.svg';

import { lightTheme } from '@/styles/theme';

import { MarkerContent } from './styles';

interface MapMarkerProps {
  latitude: number;
  longitude: number;
}

/**
 * Marker for a single organization location
 */

export const MapMarker = ({ latitude, longitude }: MapMarkerProps) => (
  <Marker latitude={latitude} longitude={longitude} anchor="bottom">
    <MarkerContent onClick={() => console.log('Clicked on organisation')}>
      <LocationIcon fill={lightTheme.colors.primary} />
    </MarkerContent>
  </Marker>
);
