'use client';

import { IconBox, IconButtonContainer } from './styles';

export interface IconButtonProps {
  icon: React.ReactNode;
  iconColor?: string;
  onClick: () => void;
}

export const IconButton = ({ icon, iconColor, onClick }: IconButtonProps) => {
  return (
    <IconButtonContainer onClick={onClick}>
      <IconBox $iconColor={iconColor}>{icon}</IconBox>
    </IconButtonContainer>
  );
};
