import styled from 'styled-components';

export const ButtonContainer = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.spacing(1)}px;
  width: 100%;
  padding-inline: ${(props) => props.theme.spacing(2)}px;
  padding-block: ${(props) => props.theme.spacing(1)}px;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  border-radius: 6.25rem;
  border: 1px solid ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.primary};
  font-size: ${(props) => props.theme.typography5.fontSize};
  font-weight: ${(props) => props.theme.typography5.fontWeight};
  line-height: ${(props) => props.theme.typography5.lineHeight};

  &:active {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.backgroundPrimary};

    svg {
      fill: ${(props) => props.theme.colors.backgroundPrimary};
      stroke: ${(props) => props.theme.colors.backgroundPrimary};
    }
  }
`;

export const IconContainer = styled.span`
  display: flex;
  svg {
    fill: ${(props) => props.theme.colors.primary};
  }
`;
