import { useEffect, useRef, useState } from 'react';
import { default as MapBox, GeolocateControl, MapRef, NavigationControl } from 'react-map-gl';

import { useMemberContext } from '@/context/useMemberContext';
import { PointFeature, useMapClusters } from '@/hooks/useMapClusters';
import { useOrganisations } from '@/hooks/useOrganisations';

import { ClusterMarker } from './ClusterMarker';
import { MapMarker } from './MapMarker';
import { MapContainer } from './styles';

// Initial map view ( will change in the future, when the new feature will be added)
const INITIAL_VIEWPORT = {
  latitude: 52.092876,
  longitude: 5.10448,
  zoom: 7,
};

/**
 * Main map component showing organizations
 */

export const Map = () => {
  const mapRef = useRef<MapRef>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [viewport, setViewport] = useState(INITIAL_VIEWPORT);

  const { search, qualityLabel, governanceCode, socialCouncilWork } = useMemberContext();
  const { organisations } = useOrganisations(
    search,
    qualityLabel,
    governanceCode,
    socialCouncilWork,
  );
  const { points, supercluster, updateClusters } = useMapClusters(organisations || [], mapRef);

  // Initialize map
  const handleMapLoad = () => {
    if (!mapRef.current || mapLoaded) return;
    setMapLoaded(true);
    setTimeout(() => updateClusters(), 100);
  };

  const handleViewportChange = () => {
    if (!mapRef.current) return;
    const map = mapRef.current.getMap();
    setViewport({
      latitude: map.getCenter().lat,
      longitude: map.getCenter().lng,
      zoom: map.getZoom(),
    });
    updateClusters();
  };

  // Handle clicking a group marker
  const handleClusterClick = (cluster: PointFeature) => {
    if (!supercluster || !cluster.properties.cluster_id || !mapRef.current) return;
    const expansionZoom = Math.min(
      supercluster.getClusterExpansionZoom(cluster.properties.cluster_id) + 1,
      20,
    );
    mapRef.current.flyTo({
      center: cluster.geometry.coordinates as [number, number],
      zoom: expansionZoom,
      duration: 500,
    });
  };

  // change view port when organisations change
  useEffect(() => {
    const minLat = Math.min(...organisations.map((o) => +o.Latitude));
    const maxLat = Math.max(...organisations.map((o) => +o.Latitude));
    const minLng = Math.min(...organisations.map((o) => +o.Longitude));
    const maxLng = Math.max(...organisations.map((o) => +o.Longitude));
    mapRef.current?.fitBounds([minLng, minLat, maxLng, maxLat], { padding: 100, maxZoom: 10 });
  }, [organisations]);

  return (
    <MapContainer>
      <MapBox
        ref={mapRef}
        onLoad={handleMapLoad}
        mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_PUBLIC_KEY}
        initialViewState={viewport}
        onMoveEnd={handleViewportChange}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v9">
        <NavigationControl />
        <GeolocateControl />

        {points.map((point, index) => {
          if (point.properties.cluster) {
            return (
              <ClusterMarker
                key={`cluster-${index}`}
                point={point}
                onClusterClick={handleClusterClick}
              />
            );
          }

          const [longitude, latitude] = point.geometry.coordinates;
          return <MapMarker key={`marker-${index}`} latitude={latitude} longitude={longitude} />;
        })}
      </MapBox>
    </MapContainer>
  );
};
