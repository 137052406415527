'use client';

import { useMemberContext } from '@/context/useMemberContext';

import { DetailsContainer } from './styles';

export const Details = () => {
  const { setMember } = useMemberContext();

  return (
    <DetailsContainer>
      Details
      <br />
      <button onClick={() => setMember(null)}>Close</button>
    </DetailsContainer>
  );
};
