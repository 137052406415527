import { Marker } from 'react-map-gl';

import { PointFeature } from '@/hooks/useMapClusters';

import { MarkerCicrcle } from './styles';

interface ClusterMarkerProps {
  point: PointFeature;
  onClusterClick: (point: PointFeature) => void;
}

/**
 * Marker for a group of nearby locations
 */

export const ClusterMarker = ({ point, onClusterClick }: ClusterMarkerProps) => {
  const [longitude, latitude] = point.geometry.coordinates;

  return (
    <Marker
      key={`cluster-${point.properties.cluster_id}`}
      latitude={latitude}
      longitude={longitude}>
      <MarkerCicrcle onClick={() => onClusterClick(point)}>
        {point.properties.point_count}
      </MarkerCicrcle>
    </Marker>
  );
};
