import styled from 'styled-components';

import { breakpoints } from '@/styles/breakpoint';
export const FilterContainer = styled.div``;

export const FilterModal = styled.div(
  ({ theme }) => `
  position: absolute;
  width: 100%;
  height: 10rem;
  left: 0;
  top: ${theme.dimensions.searchFilterHeight}px;
  background-color: green;

  ${breakpoints.mobile} {
    position: fixed;
    background-color: blue;
    top: calc(${theme.dimensions.mobileHeaderHeight}px + ${theme.dimensions.searchFilterHeight}px);
  }
`,
);
